"use client";

import Image from "next/image";
import { useEffect, useState } from "react";
import { Link } from "@/i18n/routing";
import DonationBreakdownModal from "../components/modals/DonationBreakdownModal";
import SubscriptionOptions, { SubscriptionTier } from "../components/subscriptions/SubscriptionOptions";
import { useTranslations } from "next-intl"; // ✅ next-intl 훅 추가
import Marquee from "../components/Marquee";

const subscriptionTiers: SubscriptionTier[] = [
  { id: 'donate5', price: 5 },  //double check
  { id: 'donate25', price: 25 },
  { id: 'donate50', price: 50 },
  { id: 'donate100', price: 100 },
];

// const descriptions = {
//   donate25: '$25 could pay for essential equipment support for cats in need.',
//   donate50: '$50 could pay for two crucial kit checks at the vet for a cat as soon as they arrive with us.',
//   donate100: '$100 could cover advanced medical care and support transformative rescues for cats in need.',
// };


// Define a type based on the Fundraising model structure
type Fundraising = {
  id: number;
  name: string;
  title: string;
  description: string;
  imageUrl: string;
  targetAmount: number;
  currentAmount: number;
  status: string;
  endDate?: string;
};

type DonationData = {
  totalFundedAmount: number;
  donations: {
    donor: string;
    totalAmount: number;
  }[];
};

type Statistics = {
  totalRescued: number;
  totalFundsRaised: number;
  totalSupporters: number;
  totalCatsUnderCare: number;
};

const handleSubscribe = (selectedTier: string) => {
  // 예: 선택된 티어에 따라 페이지 이동 또는 API 호출
  window.location.href = `/s/${selectedTier}`;
};

export default function Home() {
  const [activeProjects, setActiveProjects] = useState<Fundraising[]>([]);
  const [completedProjects, setCompletedProjects] = useState<Fundraising[]>([]);
  const [donationData, setDonationData] = useState<Record<number, DonationData>>({});
  const [statistics, setStatistics] = useState<Statistics | null>(null);
  
  const t = useTranslations("Home"); // ✅ "Home" 키 기준으로 번역 데이터 가져오기


  useEffect(() => {
    const fetchFundraisingProjects = async () => {
      try {
        const activeRes = await fetch("/api/fundraising/get?status=ACTIVE");
        const activeData: Fundraising[] = await activeRes.json();
        setActiveProjects(activeData.slice(0, 3)); // 상태 업데이트
  
        // 상태 업데이트 이후 donation 데이터 가져오기
        for (const project of activeData.slice(0, 3)) {
          await fetchDonationData(project.id);
        }
  
        const completedRes = await fetch("/api/fundraising/get?status=COMPLETED");
        const completedData: Fundraising[] = await completedRes.json();
        setCompletedProjects(completedData.slice(0, 3)); // 상태 업데이트
      } catch (error) {
        console.error("Error fetching fundraising projects:", error);
      }
    };

    const fetchDonationData = async (id: number) => {
      try {
        const res = await fetch(`/api/donation/${id}`);
        const data: DonationData = await res.json();
        setDonationData((prevData) => ({
          ...prevData,
          [id]: data,
        }));
      } catch (error) {
        console.error(`Error fetching donation data for project ${id}:`, error);
      }
    };

    const fetchStatistics = async () => {
      try {
        const res = await fetch("/api/statistics/get");
        const data: Statistics = await res.json();
        setStatistics(data);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchFundraisingProjects();
    fetchStatistics();
  }, []);


  return (
    <div className="min-h-screen flex flex-col items-center bg-white text-black">
      {/* Banner Section */}
      <Marquee />

      <section className="w-full h-[40vh] md:h-[70vh] relative flex flex-col justify-center items-center text-white text-center">
        <Image
          className="absolute inset-0 w-full h-full object-cover opacity-100"
          src="/banners/banner.png"
          alt="YongsanCats Banner"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          priority
        />
        <div className="relative z-10 mb-auto mt-4 md:mt-20 px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-2 md:mb-4 drop-shadow-lg">
          {t("title")}
          </h1>
          <p className="text-lg md:text-xl drop-shadow-md">
          {t("subtitle")}
          <span className="block md:inline ml-2">{t("subtitle_span")}</span>
          </p>

        </div>
        <div className="relative z-10 mt-auto mb-10 md:mb-20">
          <Link
            href="/s"
            className="inline-block px-8 py-4 text-md md:text-lg font-semibold bg-black text-white rounded-full hover:bg-buttonYellow transition-colors shadow-lg"
          >
          {t("donate_button")}
          </Link>
        </div>
      </section>

      {/* Active Projects Section */}
      <main className="flex flex-col items-center max-w-5xl mx-auto gap-4 text-center bg-ivory">
        <h2 className="text-xl md:text-4xl mt-12 font-semibold text-black"> {t("active_projects_section_title")}
        </h2>
        <section className="grid gap-8 p-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full max-w-full">
        {activeProjects.map((project) => {
            const donationInfo = donationData[project.id];
            const totalFunded = donationInfo?.totalFundedAmount || 0;
            const progressPercentage = Math.min((totalFunded / project.targetAmount) * 100, 100);

            return (
              <div
                key={project.id}
                className="bg-white p-4 shadow-lg rounded-lg hover:shadow-xl transition-shadow"
              >
                 <div className="relative w-full h-60">
                <Image
                  src={project.imageUrl}
                  alt={`Rescue Project ${project.name}`}
                  fill
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                 </div>

                <h3 className="text-lg md:text-xl font-semibold mb-2 text-black">{project.title}</h3>
                <p className="text-sm text-black/80 mb-4 line-clamp-3 overflow-hidden text-ellipsis">
                  {project.description}
                </p>
                <div className="w-full bg-gray-300 rounded-full h-2.5 mb-2">
                  <div
                    className="bg-customOrange h-2.5 rounded-full transition-width duration-500"
                    style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
                <p className="text-sm font-semibold text-black mb-4">
                  ${totalFunded} {t("raised_text")} ${project.targetAmount}
                </p>
                <Link
                  href={`/d/${project.id}`}
                  className="inline-block px-4 py-2 text-sm font-semibold bg-buttonYellow text-black rounded-full hover:bg-customYellowHover transition-colors"
                >
                  {t("donate_now")}
                </Link>
              </div>
            );
          })}
        </section>

        {/* Impact and Transparency Section */}
        <section className="flex flex-col items-center w-full mx-auto py-10 sm:px-20 gap-8 bg-lightIvory text-black">
          <DonationBreakdownModal />
          <p className="text-sm md:text-lg max-w-2xl mt-2 text-black/80 text-center">
          {t("contribution_message")}
          </p>
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 w-full max-w-5xl">
            <div className="bg-white p-6 shadow-lg rounded-lg text-center hover:shadow-xl transition-shadow">
              <h3 className="text-2xl md:text-4xl font-bold text-customRed">{statistics?.totalCatsUnderCare || 0}+</h3>
              <p className="text-sm md:text-lg text-black/80"> {t("total_cats_under_care")}</p>
            </div>
            <div className="bg-white p-6 shadow-lg rounded-lg text-center hover:shadow-xl transition-shadow">
              <h3 className="text-2xl md:text-4xl font-bold text-customRed">{statistics?.totalRescued || 0}+</h3>
              <p className="text-sm md:text-lg text-black/80"> {t("total_cats_rescued")}</p>
            </div>
            <div className="bg-white p-6 shadow-lg rounded-lg text-center hover:shadow-xl transition-shadow">
            <h3 className="text-2xl md:text-4xl font-bold text-customRed">
            {statistics?.totalFundsRaised !== undefined
              ? `$${statistics.totalFundsRaised.toLocaleString()}`
              : "$0"}
          </h3>

              <p className="text-sm md:text-lg text-black/80">{t("total_donations_received")}</p>
            </div>
          </div>
        </section>

        {/* Completed Projects Section */}
        <section className="flex flex-col items-center max-w-5xl mx-auto p-4 gap-8 bg-ivory text-black">
          <p className="text-sm font-semibold md:text-lg max-w-2xl text-black/80 text-center">
          {t("completed_projects_description")}
          </p>
          <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full max-w-full">
            {completedProjects.map((project) => (
              <div
                key={project.id}
                className="bg-white p-4 shadow-lg rounded-lg hover:shadow-xl transition-shadow"
              >
                <div className="relative w-full h-60">
                <Image
                  src={project.imageUrl}
                  alt={`Rescue Project ${project.name}`}
                  fill
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                </div>

                <h3 className="text-2xl font-semibold mb-2 text-black">{project.name}</h3>
                <p
                  className="text-sm text-black/80 mb-4 line-clamp-3 overflow-hidden text-ellipsis"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                  }}
                >
                  {project.description}
                </p>
                <Link
                  href={`/d/${project.id}`}
                  className="text-customBlue font-semibold text-md hover:underline"
                >
                   {t("learn_more")}
                </Link>
              </div>
            ))}
          </div>
        </section>

                {/* Monthly giving section */}
                <section className="flex flex-col md:flex-row items-center w-full mx-auto p-4 gap-8 bg-buttonYellow text-black">
  {/* Left Side: Text */}
  <div className="flex-1 flex flex-col items-start p-4">
    <h2 className="text-5xl font-bold">  {t("monthly_giving_section_title")}</h2>
    <p className="mt-4 text-lg">
    {t("monthly_giving_description")}
    </p>
  </div>
  
  {/* Right Side: Subscription Options */}
  <div className="flex-1 flex flex-col items-center p-4">
    <SubscriptionOptions
      subscriptionTiers={subscriptionTiers}
      // descriptions={descriptions}
      onSubscribe={handleSubscribe}
    />
  </div>
</section>


      </main>
    </div>
  );
}
