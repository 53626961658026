// components/DonationBreakdownModal.tsx

import { useState, useEffect } from "react";
import { 
  FaInfoCircle, FaStethoscope, FaUtensils, FaSyringe, FaBath, FaVial, FaBug, FaMedkit, 
  FaHome, FaCarSide, FaClipboardCheck, FaNotesMedical, FaPills, FaHeartbeat, FaMicrochip, 
  FaBandAid, FaSoap, FaClinicMedical 
} from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import { useTranslations } from "next-intl"; // 다국어 지원 추가

export default function DonationBreakdownModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [iconSize, setIconSize] = useState(16); // Default icon size for hydration-safe SSR
  const t = useTranslations("DonationBreakdownModal");

  // Dynamically adjust icon size based on screen size
  const isMdOrLarger = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    setIconSize(isMdOrLarger ? 24 : 16);
  }, [isMdOrLarger]);

  const donationItems = [
    { icon: <FaStethoscope size={iconSize} />, name: t("items.spaying_neutering"), cost: 300 },
    { icon: <FaUtensils size={iconSize} />, name: t("items.food_supplies"), cost: 150 },
    { icon: <FaSyringe size={iconSize} />, name: t("items.vaccination"), cost: 50 },
    { icon: <FaBath size={iconSize} />, name: t("items.grooming"), cost: 110 },
    { icon: <FaVial size={iconSize} />, name: t("items.blood_tests"), cost: 60 },
    { icon: <FaBug size={iconSize} />, name: t("items.parasite_treatment"), cost: 60 },
    { icon: <FaMedkit size={iconSize} />, name: t("items.emergency_medical"), cost: 900 },
    { icon: <FaHome size={iconSize} />, name: t("items.shelter"), cost: 50 },
    { icon: <FaCarSide size={iconSize} />, name: t("items.transportation"), cost: 100 },
    { icon: <FaNotesMedical size={iconSize} />, name: t("items.fiv_felv_testing"), cost: 120 },
    { icon: <FaPills size={iconSize} />, name: t("items.deworming"), cost: 100 },
    { icon: <FaHeartbeat size={iconSize} />, name: t("items.health_checkups"), cost: 100 },
    { icon: <FaMicrochip size={iconSize} />, name: t("items.microchip"), cost: 30 },
    { icon: <FaBandAid size={iconSize} />, name: t("items.wound_care"), cost: 1800 },
    { icon: <FaSoap size={iconSize} />, name: t("items.sanitary_supplies"), cost: 190 },
    { icon: <FaClinicMedical size={iconSize} />, name: t("items.veterinarian_fees"), cost: 28 },
    { icon: <FaClipboardCheck size={iconSize} />, name: t("items.adoption_process"), cost: 360 },
  ];

  return (
    <div className="flex flex-col items-center w-full py-0 px-8 sm:px-20 gap-4 md:gap-8 bg-lightIvory text-black">
      <div className="flex items-center">
      <h2 className="text-xl md:text-3xl font-semibold">{t("title")}</h2>
      <button
          onClick={() => setIsModalOpen(true)}
          className="ml-2 text-customBlue focus:outline-none"
          aria-label="See donation amounts"
        >
          <FaInfoCircle size={24} />
        </button>
      </div>
      <p className="text-sm md:text-lg max-w-2xl text-black text-center">
      {t("description")}
        <span className="block md:inline ml-2">{t("sub_description")}</span>
      </p>

      <div className="grid gap-2 md:gap-8 sm:grid-cols-1 lg:grid-cols-2 w-full max-w-5xl">
        <ul className="space-y-4">
          {donationItems.slice(0, Math.ceil(donationItems.length / 2)).map((item, index) => (
            <li key={index} className="flex items-center">
              <span className="mr-3">{item.icon}</span>
              <p className="text-sm md:text-lg text-black">{item.name}</p>
            </li>
          ))}
        </ul>
        <ul className="space-y-4 mt-2 md:mt-0">
          {donationItems.slice(Math.ceil(donationItems.length / 2)).map((item, index) => (
            <li key={index} className="flex items-center">
              <span className="mr-3">{item.icon}</span>
              <p className="text-sm md:text-lg text-black">{item.name}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none text-2xl p-2"
              onClick={() => setIsModalOpen(false)}
              aria-label="Close modal"
            >
              &times;
            </button>
            <h3 className="text-2xl font-semibold mb-4">{t("modal_title")}</h3>
            <h3 className="text-xs mb-4 text-left">
            {t("modal_note")}
            </h3>
            <ul className="space-y-2">
              {donationItems.map((item, index) => (
                <li key={index} className="flex justify-between">
                  <span>{item.name}</span>
                  <span>${item.cost}+</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
