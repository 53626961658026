"use client"; // Next.js 15에서는 useEffect를 사용하려면 필요함

import React, { useEffect, useState, useRef } from "react";
import { useTranslations } from "next-intl"; // ✅ 다국어 지원 추가

// 🔹 기부자 데이터 타입 정의
interface Supporter {
  name: string;
}

const Marquee: React.FC = () => {
  const [supporters, setSupporters] = useState<string[]>([]);
  const marqueeRef = useRef<HTMLDivElement>(null);
  const t = useTranslations("Marquee"); // ✅ 다국어 지원 추가
  const donatorLabel: string = t("donator"); // ✅ 번역된 Donator 문자열 저장
  const [animationDuration, setAnimationDuration] = useState<string>("15s"); // 기본값
  const [isAnimating, setIsAnimating] = useState<boolean>(true); // 애니메이션 상태 관리

  useEffect(() => {
    const fetchSupporters = async (): Promise<void> => {
      try {
        const res = await fetch("/api/supporters");
        if (!res.ok) throw new Error("Failed to fetch");
        const data: Supporter[] = await res.json();
        setSupporters(data.map((s: Supporter) => s.name));
      } catch (error) {
        console.error("Error fetching supporters:", error);
      }
    };

    fetchSupporters();
  }, []);

  // 🔹 기부자 리스트에 "Donator" 추가
  const insertDonatorLabel = (names: string[]): string[] => {
    const result: string[] = [donatorLabel]; // 맨 앞에 Donator 추가
    names.forEach((name, index) => {
      result.push(name);
      if ((index + 1) % 10 === 0) {
        result.push(donatorLabel);
      }
    });
    return result;
  };

  const formattedSupporters: string[] = insertDonatorLabel(supporters);

  // 🔥 마운트 후 텍스트 길이에 따라 애니메이션 시간 조정 (containerWidth 제거)
  useEffect(() => {
    if (marqueeRef.current) {
      const textWidth: number = marqueeRef.current.scrollWidth; // 전체 텍스트 길이
      const speedFactor: number = 0.01; // 1px당 0.05초
      const newDuration: string = `${Math.max(15, textWidth * speedFactor)}s`; // 최소 15초 이상
      setAnimationDuration(newDuration);
    }
  }, [formattedSupporters]);

  // 🔥 애니메이션 끝난 후 다시 시작
  const handleAnimationEnd = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setIsAnimating(true);
    }, 1000); // 1초 후 다시 시작
  };

  return (
    <div className="relative w-full overflow-hidden bg-buttonYellow py-2">
      <div
        ref={marqueeRef}
        className="whitespace-nowrap flex gap-8"
        style={{
          animation: isAnimating ? `marquee ${animationDuration} linear` : "none", // ⬅️ 애니메이션 끝나면 멈춤
        }}
        onAnimationEnd={handleAnimationEnd} // ⬅️ 애니메이션 끝나면 다시 시작
      >
        {formattedSupporters.map((text: string, index: number) => (
          <span
            key={index}
            className={`font-bold text-lg ${
              text === donatorLabel ? "text-customBlue" : "text-black"
            }`}
          >
            {text}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Marquee;
