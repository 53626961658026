'use client';
import { useState } from 'react';
import { PiArrowBendLeftUpLight } from 'react-icons/pi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useTranslations } from "next-intl";
import { Link } from "@/i18n/routing";

export interface SubscriptionTier {
  id: string;
  price: number;
}

export interface SubscriptionOptionsProps {
  subscriptionTiers: SubscriptionTier[];
  onSubscribe: (selectedTier: string) => void;
}

const SubscriptionOptions: React.FC<SubscriptionOptionsProps> = ({
  subscriptionTiers,
}) => {
  const [selectedTier, setSelectedTier] = useState<string>("donate25"); // ✅ 기본값을 $25로 설정
  const t = useTranslations("SubscriptionOptions");

  return (
    <div className="relative w-full max-w-lg bg-white rounded-lg shadow-xl p-8">
      {/* Tabs Section */}
      <div className="flex items-center justify-center mb-6">
        <div className="flex w-full max-w-lg bg-gray-100 rounded-lg overflow-hidden">
          <div className="w-full py-2 text-center bg-customBlue text-white text-xl font-bold flex items-center justify-center">
            <span className="mr-2 py-4">📅</span> {t("monthly_tab")}
          </div>
        </div>
      </div>

      {/* Tier Selection */}
      <div className="text-center text-xl py-2 font-extrabold">{t("title")}</div>

      <div>
        <div className="grid grid-cols-3 gap-4 mt-8">
          {/* ✅ subscriptionTiers에서 $25 찾아서 첫 번째 줄에 배치 */}
          {subscriptionTiers
            .filter((tier) => tier.id === "donate25") // $25만 첫째 줄에 표시
            .map((tier) => (
              <button
                key={tier.id}
                onClick={() => setSelectedTier(tier.id)}
                className={`h-16 col-span-3 w-full flex flex-col justify-center items-center text-center font-bold rounded-lg border transition-all duration-200 ${
                  selectedTier === tier.id
                    ? "bg-black text-white border-buttonYellow"
                    : "bg-white text-gray-800 shadow-sm hover:bg-gray-100 border-gray-300"
                }`}
              >
                <span className={`text-xl font-bold ${selectedTier === tier.id ? "text-white" : "text-gray-900"}`}>
                  ${tier.price}
                </span>
                <span className={`text-xs font-medium ${selectedTier === tier.id ? "text-buttonYellow" : "text-gray-500"}`}>
                  ✔ {t("most_popular")}
                </span>
              </button>
            ))}

          {/* ✅ 나머지 $5, $50, $100을 아래에 배치 */}
          {subscriptionTiers
            .filter((tier) => tier.id !== "donate25") // $25는 위에서 처리했으므로 제외
            .map((tier) => (
              <button
                key={tier.id}
                onClick={() => setSelectedTier(tier.id)}
                className={`h-12 w-full flex flex-col justify-center items-center text-center font-bold rounded-lg border transition-all duration-200 ${
                  selectedTier === tier.id
                    ? "bg-black text-white border-black"
                    : "bg-white text-gray-800 shadow-sm hover:bg-gray-100 border-gray-300"
                }`}
              >
                <span className={`text-lg font-semibold ${selectedTier === tier.id ? "text-white" : "text-black"}`}>
                  ${tier.price}
                </span>
              </button>
            ))}
        </div>

        <div className="flex items-center flex-col mt-2">
          <div className="flex items-center justify-start w-full">
            <PiArrowBendLeftUpLight className="w-4 h-4 ml-4 text-black mr-1" />
            <div className="text-xs font-bold mt-2 text-black">{t("most_popular")}</div>
          </div>
        </div>
      </div>

      {/* Dynamic Description */}
      <div className="bg-white text-sm mt-4 p-2 rounded-lg flex items-center border shadow-lg">
        {selectedTier ? (
          <>
            <div className="w-1/3 flex justify-center items-center py-6">
              {selectedTier === "donate5" && <span className="text-4xl">🍚</span>}  {/* Daily meal & fresh water */}
              {selectedTier === "donate25" && <span className="text-4xl">🎁</span>}  {/* Equipment support */}
              {selectedTier === "donate50" && <span className="text-4xl">🩺</span>}  {/* Vet check */}
              {selectedTier === "donate100" && <span className="text-4xl">❤️‍🩹</span>}  {/* Advanced medical care & rescues */}
            </div>
            <div className="w-2/3 text-gray-700">
              <p>{t(`descriptions.${selectedTier}`)}</p>
            </div>
          </>
        ) : (
          <div className="w-full text-center py-6">
            <p>{t("select_tier_message")}</p>
          </div>
        )}
      </div>

      {/* Subscribe Button with Link */}
      {selectedTier ? (
        <Link
          href={`/s/${selectedTier}`}
          className="w-full mt-8 bg-buttonYellow hover:bg-yellow-600 text-black font-bold py-3 px-6 rounded-lg flex items-center justify-center"
        >
          <span className="mr-2">{t("subscribe_button")}</span>
          <MdKeyboardArrowRight className="w-4 h-4" />
        </Link>
      ) : (
        <button
          onClick={() => alert(t("select_donation_alert"))}
          className="w-full mt-8 bg-gray-400 text-white font-bold py-3 px-6 rounded-lg flex items-center justify-center cursor-not-allowed"
        >
          <span className="mr-2">{t("subscribe_button")}</span>
          <MdKeyboardArrowRight className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};

export default SubscriptionOptions;
